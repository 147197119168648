'use client';

import { useCallback } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { H2, H3, Item, OutboundLink, Paragraph } from '@/components/primitives';

const CommunitySection = () => {
  const handleClick = useCallback(() => {
    withReactContent(Swal).fire({
      icon: 'info',
      title: '안내',
      html: (
        <ul className='ml-5 list-outside list-disc'>
          <Item>
            서버 초대 주소:{' '}
            <OutboundLink href='https://discord.gg/jxFQhg5vHv'>https://discord.gg/jxFQhg5vHv</OutboundLink>
          </Item>
          <Item>
            서버에 처음 입장하면 <mark className='bg-blue-100 font-bold text-blue-800'>#환영합니다</mark> 채널에만
            입장하실 수 있습니다.
          </Item>
          <Item>
            나머지 채널을 이용하시려면 <mark className='bg-blue-100 font-bold text-blue-800'>#환영합니다</mark> 채널에서{' '}
            <mark className='bg-blue-100 font-bold text-blue-800'>@추가아이템</mark>을 호출해주셔야만 합니다.
          </Item>
          <Item>별도의 인사나 질문, DM을 보내시는 행위는 모두 무시됩니다.</Item>
          <Item>
            위 절차를 따르지 않으시는 분들은 서버 규칙에 동의하지 않는 것으로 간주하고 채널에서 추방조치 됩니다.
          </Item>
        </ul>
      ),
    });
  }, []);
  return (
    <section id='community' className='bg-white p-5 pb-12'>
      <H2>스킨 사용자 대화방</H2>
      <Paragraph>
        추가아이템 스킨을 사용하시는 분들을 위한 대화방이 있습니다. Discord 추가아이템 공방 서버에 참여하시면 스킨에
        대해 궁금한 점이나 문제가 있을 때 도움을 받으실 수 있습니다.
      </Paragraph>
      <section className='m-5 rounded-xl bg-blue-50 p-5'>
        <H3>서버 규칙</H3>
        <Paragraph>
          <time dateTime='2024-09-20'>2024년 09월 20일</time> 수정
        </Paragraph>
        <ol className='list-outside list-decimal pl-5'>
          <Item>상대방을 존중하는 표현을 사용해주세요. 모욕적이거나 모멸감을 주는 표현의 사용을 금지합니다.</Item>
          <Item>
            올바른 표현을 사용해주세요. 보호받아야할 계층에 대한 혐오표현을 금지합니다. 이는 성별, 성 정체성, 연령, 인종
            등의 여러가지 부분을 아우릅니다.
            <ul className='ml-5 list-outside list-disc'>
              <Item>
                참고자료 1: 당신은 ○린이도 응애도 아닙니다 -{' '}
                <OutboundLink href='https://item4.blog/2023-10-13/neither-a-child-nor-a-baby/'>
                  https://item4.blog/2023-10-13/neither-a-child-nor-a-baby/
                </OutboundLink>
              </Item>
            </ul>
          </Item>
          <Item>타인의 스킨(모드)을 허가없이 수정, 배포 혹은 수정 후 재배포 하는 행위를 일체 금지합니다.</Item>
          <Item>
            거래 행위는 내용과 수단, 방법을 막론하고 금지합니다. 이는 관리자가 거래 도중이나 결과에 이르기까지의 내용을
            중재하거나 관리하는 것이 현실적으로 불가능하기 때문입니다. 거래를 암시하거나 유도하는 행위 또한 금지합니다.
          </Item>
          <Item>
            채널의 용도를 준수해주세요. 다른 이용자들이 대화내역을 참조할 수 있도록 채널을 분리해놓았으니 가급적 채널별
            용도에 맞는 대화 부탁드립니다.
          </Item>
        </ol>
      </section>
      <Paragraph>위 서버 규칙을 확인하였고, 동의하시는 분께서는 아래 버튼을 눌러 서버 초대 URL을 받아주세요.</Paragraph>
      <div className='mt-5 flex justify-center'>
        <button
          type='button'
          className='rounded-lg bg-blue-500 p-5 text-lg text-white hover:bg-blue-800'
          onClick={handleClick}
        >
          위 내용에 동의하며, 서버 참가를 희망합니다.
        </button>
      </div>
      <Paragraph></Paragraph>
    </section>
  );
};
export default CommunitySection;
