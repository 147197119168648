import type { ImageProps } from 'next/image';
import type { FC, HTMLProps, PropsWithChildren, ReactNode } from 'react';

import Image from 'next/image';

export const H2: FC<PropsWithChildren> = ({ children }) => <h2 className='mb-4 mt-8 text-3xl font-bold'>{children}</h2>;
export const H3: FC<PropsWithChildren> = ({ children }) => <h3 className='mb-2 mt-4 text-2xl font-bold'>{children}</h3>;
export const Paragraph: FC<PropsWithChildren> = ({ children }) => <p className='mb-1 text-md'>{children}</p>;
type FigureProps = {
  src: ImageProps['src'];
  alt: string;
  caption?: ReactNode;
};
export const Figure: FC<FigureProps> = ({ src, alt, caption }) => {
  return (
    <div>
      <figure className='mt-8 w-full text-center'>
        <Image src={src} alt={alt} className='mx-auto max-w-halfvw' />
        <figcaption className='mt-3 text-sm'>{caption || alt}</figcaption>
      </figure>
    </div>
  );
};
export const Item: FC<PropsWithChildren> = ({ children }) => (
  <li className='mb-2 text-left text-md last-of-type:mb-0'>{children}</li>
);
export const Code: FC<PropsWithChildren> = ({ children }) => (
  <code className='select-all rounded-md bg-gray-700 px-2 py-1 text-white'>{children}</code>
);
export const UnorderedList: FC<PropsWithChildren> = ({ children }) => <ul className='ml-8 list-disc'>{children}</ul>;
export const Keyboard: FC<PropsWithChildren> = ({ children }) => (
  <kbd className='rounded-md border-2 border-gray-600 bg-gray-300 px-1.5 py-0.5 font-bold'>{children}</kbd>
);
export const ItemName: FC<PropsWithChildren> = ({ children }) => (
  <span className='bg-gray-900 px-2 py-1 text-lg font-medium text-white'>{children}</span>
);
export const DescKey: FC<PropsWithChildren<HTMLProps<HTMLDListElement>>> = ({ className, children }) => (
  <dt className={className || 'text-lg'}>{children}</dt>
);
export const DescValue: FC<PropsWithChildren> = ({ children }) => (
  <dd className='mb-3 ml-10 mt-1 text-md'>{children}</dd>
);
export const OutboundLink: FC<PropsWithChildren<{ href: string }>> = ({ children, href }) => (
  <a
    href={href}
    target='_blank'
    rel='noopener noreferrer external'
    className='text-blue-600 underline hover:text-blue-800'
  >
    {children}
  </a>
);
